function createCookie(name,value,days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime()+(days*24*60*60*1000));
        expires = "; expires="+date.toGMTString();
    }
    document.cookie = name+"="+value+expires+"; path=/";
}

function floGetCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i=0; i<ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1);
        if (c.indexOf(name) === 0) return c.substring(name.length,c.length);
    }
    return "";
}

//  we consider screens larger than 760 not beeing mobile
var isNotMobile = window.matchMedia("only screen and (min-width: 760px)", window.devicePixelRatio );
//console.log(isMobile.matches);

(function(){

    /* MOBILE COOKIE START */
    var mobile_cookie_name = "flo_small_screen",
        mobile_cookie = floGetCookie(mobile_cookie_name), // Can return "1", "0", null;
        set_mobile = function(value) {
            createCookie(mobile_cookie_name, value, 1);
        },
// is_mobile_screen = screen.width <= 640;
        is_mobile_screen = document.documentElement.clientWidth <= 640;
    if (is_mobile_screen) {
        if (mobile_cookie === '' || mobile_cookie == "0") {
            set_mobile(1);
            location.reload();
        }
    } else {
        if (mobile_cookie === '' || mobile_cookie == "1") {
            set_mobile(0);
            location.reload();
        }
    }
    /* MOBILE COOKIE END */

// Set the cookie for the retina devices
// the cookie is used later to serve appropriate image size
	if( document.cookie.indexOf('flo_device_pixel_ratio') == -1 && 'devicePixelRatio' in window && window.devicePixelRatio == 2 && isNotMobile ){

		var date = new Date();

		date.setTime( date.getTime() + 3600000 );

		document.cookie = 'flo_device_pixel_ratio=' + window.devicePixelRatio + ';' + ' expires=' + date.toUTCString() +'; path=/';

		//if cookies are not blocked, reload the page

		if(document.cookie.indexOf('flo_device_pixel_ratio') != -1) {

			window.location.reload();

		}

	} else if(document.cookie.indexOf('flo_device_pixel_ratio') != -1 && floGetCookie('flo_device_pixel_ratio') != window.devicePixelRatio){
        // delete the coockie if the saved cookie does not match the current device pixel reatio

        var dateO = new Date();
        dateO.setTime( dateO.getTime() - 3600000 ); // set a past date that will be used to make the cookie expired

        document.cookie = 'flo_device_pixel_ratio=' + window.devicePixelRatio + ';' + ' expires=' + dateO.toUTCString() +'; path=/';

        window.location.reload(); // reload the page after deletting the cookie
    }

    // skip-link-focus-fix.js

    var is_webkit = navigator.userAgent.toLowerCase().indexOf( 'webkit' ) > -1,
        is_opera  = navigator.userAgent.toLowerCase().indexOf( 'opera' )  > -1,
        is_ie     = navigator.userAgent.toLowerCase().indexOf( 'msie' )   > -1;

    if ( ( is_webkit || is_opera || is_ie ) && document.getElementById && window.addEventListener ) {
        window.addEventListener( 'hashchange', function() {
            var element = document.getElementById( location.hash.substring( 1 ) );

            if ( element ) {
                if ( ! /^(?:a|select|input|button|textarea)$/i.test( element.tagName ) ) {
                    element.tabIndex = -1;
                }

                element.focus();
            }
        }, false );
    }

    /**
    * navigation.js
    *
    * Handles toggling the navigation menu for small screens.
    */

    var container, button, menu;

    container = document.getElementById( 'site-navigation' );
    if ( ! container ) {
        return;
    }

    button = container.getElementsByTagName( 'button' )[0];
    if ( 'undefined' === typeof button ) {
        return;
    }

    menu = container.getElementsByTagName( 'ul' )[0];

    // Hide menu toggle button if menu is empty and return early.
    if ( 'undefined' === typeof menu ) {
        button.style.display = 'none';
        return;
    }

    menu.setAttribute( 'aria-expanded', 'false' );

    if ( -1 === menu.className.indexOf( 'nav-menu' ) ) {
        menu.className += ' nav-menu';
    }

    button.onclick = function() {
        if ( -1 !== container.className.indexOf( 'toggled' ) ) {
            container.className = container.className.replace( ' toggled', '' );
            button.setAttribute( 'aria-expanded', 'false' );
            menu.setAttribute( 'aria-expanded', 'false' );
        } else {
            container.className += ' toggled';
            button.setAttribute( 'aria-expanded', 'true' );
            menu.setAttribute( 'aria-expanded', 'true' );
        }
    };
})();

jQuery(document).ready(function() {


    var img_height = jQuery('.mobile_logo_left .mobile-logo').height();
    setTimeout(function() {
        jQuery('.menu-selecter').animate({
            'line-height': img_height + 'px'
        }, 1000);
    });

    var $header, $left_side, $logo, $menu, $menu_items, $right_side, header_class;
    header_class = "header.logo_center";
    if (jQuery(header_class).length) {

        $header = jQuery(header_class);
        $menu = $header.find(".header_main-nav > ul");
        $menu_items = $menu.children("li");

        $logo = $header.find(".site-logo");
        var $new_logo = jQuery($logo).clone().wrapAll("<div/>").parent().html();

        $new_logo = $new_logo.replace('<li','<div');
        $new_logo = $new_logo.replace('</li>','</div>');

        $left_side = jQuery("<ul/>");
        $right_side = jQuery("<ul/>");
        $menu_items.each(function(index, item) {
            var length;
            length = $menu_items.length;
            if (index < length / 2) {
                return $left_side.append(jQuery(item).clone());
            } else if (index >= length / 2) {
                return $right_side.append(jQuery(item).clone());
            }
        });
        $left_side = jQuery("<div class='left_side'/>").append($left_side.append('<li/>'));
        $right_side = jQuery("<div class='right_side'/>").append($right_side.prepend('<li/>'));
        $header.append(jQuery("<div class='flo_header_menu_and_logo header_main-nav  '>").append($left_side).append($new_logo).append($right_side));
        jQuery('.flo_header_menu_and_logo').wrap('<div class="row"></div>');
        jQuery('.flo_header_menu_and_logo div').wrapAll('<div class="fix"></div>');
    }


    var ipad = navigator.userAgent.match(/iPad/i);

    if(jQuery('.with-hero-slider').length > 0) {
        var y = jQuery(window).scrollTop();  //your current y position on the page
        if (ipad === 0 && y === 0) {
            jQuery(window).scrollTop(y + 40);
        }
    }
	jQuery('.flickr_badge_image').each(function(index){
		var x = index % 3;
		if(index !=1 && x == 2){
			jQuery(this).addClass('last');
		}
	});

    jQuery('.flo-social-hover').hover(
        function() {
            jQuery( this ).attr('src', jQuery( this ).data('imghover') );
        }, function() {
            jQuery( this ).attr('src', jQuery( this ).data('imgoriginal') );
        }
    );
    
    /*Tweets widget*/
    var delay = 4000; //millisecond delay between cycles
    jQuery(".dynamic .tweet_item").css('display', 'none');
    function cycleThru(variable, j) {
        var jmax = jQuery(variable + " div").length;
        jQuery(variable + " div:eq(" + j + ")")
            .css('display', 'block')
            .animate({opacity: 1}, 600)
            .animate({opacity: 1}, delay)
            .animate({opacity: 0}, 800, function () {
                if (j + 1 === jmax) {
                    j = 0;
                } else {
                    j++;
                }
                jQuery(this).css('display', 'none').animate({opacity: 0}, 10);
                cycleThru(variable, j);
            });
    }

    jQuery('.tweets').each(function (index, val) {
        //iterate through array or object
        var parent_tweets = jQuery(val).parent().attr('id');
        var actioner = '#' + parent_tweets + ' .tweets .dynamic .flo_twitter .slides_container';
        cycleThru(actioner, 0);
    });
    // Init FitVids to make the videos responsive
    jQuery(".video-element, .entry-content").fitVids();
});

// Cosmo send mail
function cosmoSendMail( form, container){
	jQuery('#cosmo-name').removeClass('invalid');
	jQuery('#cosmo-email').removeClass('invalid');

	jQuery(container).html('');
	jQuery.ajax({
		url: ajaxurl,
		data: '&action=cosmoSendContact&'+jQuery( form ).serialize(),
		type: 'POST',
		dataType: "json",
//      cache: false,
		success: function (json) {

			//jQuery('#flo-loading').fadeOut('slow'); // loading effect

			if(json.contact_name ){
				jQuery('#cosmo-name').addClass('invalid');
				jQuery(container).append(json.contact_name);
			}

			if(json.contact_email ){
				jQuery('#cosmo-email').addClass('invalid');
				jQuery(container).append(json.contact_email);
			}

			if(json.message ){
				jQuery('.flo-modal').fadeIn('slow');

				jQuery( form).find('input[type="text"], textarea').val('');

				setTimeout(function(){
					jQuery('.flo-modal').fadeOut('slow');
				},3000);
			}

		}

	});
}

