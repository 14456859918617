(function($) {
  var $video_container = $('#popup_slider');
  var $ua = navigator.userAgent.toLowerCase();
  var $isAndroid = $ua.indexOf("android") > -1; //&& ua.indexOf("mobile");


  function detectmob() { 
   if( navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i) ){
      return true;
    }
   else {
      return false;
    }
  }

  function resetMenuStyle(remAttr,addAttr){
    $('.colored').each( function(){
      var menu_item_style = $(this).attr(remAttr);
      $(this).attr(addAttr, menu_item_style).removeAttr(remAttr);
    });
  }

  var Porto = {
    // All pages
    'common': {
      init: function() {


        var stickyHeader = floConfig.enableStickyHeader ? floConfig.enableStickyHeader : false;
        var stickyHeaderBackgroundColor = floConfig.stickyHeaderBackgroundColor ? floConfig.stickyHeaderBackgroundColor : '#f2eeeb';
        var $mainHeader = $('.main-header');

        var masonryOn = $('.masonry-grid').length > 0;
        var isHeroSlider = $('.hero-slider').length > 0;


        if ($('a.back-to-top').length > 0) {
          $('a.back-to-top').click(function(event) {

            event.preventDefault();
            $('html, body').animate({
              scrollTop: 0,
             
             }, '1200');
          });
        }
        if($('.navigation-bottom').length > 0){
          $('.flo_header_menu_and_logo').css({
            'bottom': '30px',
          });
        }

        /**
        * Sticky header option
        **/
        if (stickyHeader === '1') {
          var $headerNav = $('.navigation-block'),
              headerHeight = $headerNav.height(),
              slideshow_height = $('.hero-slider').height(),headerHeightnew,
              ht = $('.site-logo').height();

          if(headerHeight === 0){
            headerHeightnew = $('header.main-header').height();
          }

          var offset = 100;

          if(slideshow_height !== 0 && slideshow_height !== null && headerHeight === 0){
            offset = slideshow_height;
          }else if(slideshow_height === 0 && slideshow_height === null && headerHeight === 0){
            offset =  headerHeightnew-150;
          }

          $headerNav.wrap('<div class="sticky-wrap"></div>');

          $('.sticky-wrap').css('height', headerHeight);
          if(!$headerNav.hasClass('sticky')){
            if($('.navigation-bottom').length > 0){
              $('.flo_header_menu_and_logo').css({
                'bottom': '30px',
              });
            }
          }

          $headerNav.waypoint({
            handler: function(direction) {
             if (direction == 'down' &&  !$headerNav.hasClass('sticky')) {
                $headerNav.addClass('sticky');
                $('.flo_header_menu_and_logo').addClass('sticky');

               if($('.logo_center').length > 0) {
                   $('.sticky-wrap .navigation-block').hide();

                   if($('.navigation-bottom').length <= 0 ){
                     $('.flo_header_menu_and_logo').css({
                       'position': 'fixed',
                       'background': stickyHeaderBackgroundColor,
                       'top': 0,
                       'padding-bottom': '20px'
                     });
                   }else{

                     $('.navigation-bottom  .flo_header_menu_and_logo').css({
                       'position': 'fixed',
                       'background': stickyHeaderBackgroundColor,
                       'bottom': 'auto',
                       'top': 0,
                       'padding-bottom': '20px',
                       'padding-top': '45px',
                     });
                   }
               }else{
                 $('.sticky-wrap .navigation-block').css('background', stickyHeaderBackgroundColor);
               }

               $('.slideshow-logo.white').hide();
               $('.slideshow-logo.dark').hide();
               resetMenuStyle('style','data-style');
             } else if($headerNav.hasClass('sticky')) {

               $('.sticky-wrap .navigation-block').css('background', '');
                if($('.navigation-bottom').length > 0){
                  $('.flo_header_menu_and_logo').css({
                    'position': 'absolute',
                    'background': 'none',
                    'top': 'inherit',
                    'bottom': '30px',
                    'height': 'inherit',
                    'padding-top': '0',
                  });
                }else{
                  $('.flo_header_menu_and_logo').css({
                    'position': 'relative',
                    'background': 'none'
                  });
                }

                $headerNav.removeClass('sticky');
                $('.flo_header_menu_and_logo').removeClass('sticky');
                resetMenuStyle('data-style','style');
                $('.slideshow-logo.white').css('display','');
                $('.slideshow-logo.dark').css('display','');
                BackgroundCheck.refresh();
             }
            },

            offset: -offset
          });
        }
        
        /**
        * Enable mobile menu 
        **/
        (function() {
          var $body = $('body');
              
          var $menuButton = $mainHeader.find('.mobile-elements a.open-menu');
          if($menuButton.length === 0){
            $menuButton = $mainHeader.find('.mobile-elements a.open-menu_label');
          }
          $menuButton.click(function(e) {
            e.preventDefault();

            $('body, html').animate({
              scrollTop: 0
            });

            $body.toggleClass('without-scroll');
            $mainHeader.toggleClass('show-mobile-menu');
          });
        })();

        /**
        * Dropdown hover fix on mobile devices
        **/
        if (detectmob()) {
          $mainHeader.find('.header_main-nav_link.menu-item-has-children > a').each(function(index, el) {

            $(this).click(function(event) {
              if (!$(this).parent().hasClass('active')) {
                event.preventDefault();

                $mainHeader.find('.header_main-nav_link.active').removeClass('active');

                $(this).parent().addClass('active');
              }
            });
          });
        }

        /**
        * Gallery slider init
        **/
        if ($('.gallery').length > 0) {
          var gallAutoplay = false;
          var gallAutoplaySpeed = 3000;
          var gallPauseHover = false;
          if(typeof flo_js_data.gallery_autoplay != 'undefined'){
            if(typeof flo_js_data.gallery_autoplay.autoplay_enabled != 'undefined' &&
                flo_js_data.gallery_autoplay.autoplay_enabled == "1"){

              gallAutoplay = true;
            }

            if(typeof flo_js_data.gallery_autoplay.autoplay_speed != 'undefined' &&
                !isNaN(flo_js_data.gallery_autoplay.autoplay_speed)){

              gallAutoplaySpeed = flo_js_data.gallery_autoplay.autoplay_speed;
            }
          }
            if(typeof flo_js_data.gallery_autoplay.autoplay_pause_hover != 'undefined' &&
                flo_js_data.gallery_autoplay.autoplay_pause_hover == "1"){

              gallPauseHover = true;
            }
  	      if ($('.gallery.horizontal-long').length > 0) {
            setTimeout(function(){
              jQuery('.gallery.horizontal-long .slider').slick('slickGoTo',0);

            }, 5);
            // gall init(h-long)
  		      $('.gallery.horizontal-long .slider').slick({
  			      variableWidth: true,
  			      infinite: true,
  			      slidesToShow: 1,
  			      lazyLoad: 'progressive',
  			      centerMode: true,
                  autoplay:gallAutoplay,
                  autoplaySpeed: gallAutoplaySpeed,
                  pauseOnHover:gallPauseHover,
              responsive: [
                {
                  breakpoint: 780,
                  settings: {
                    variableWidth: false,
                    centerMode: false,
                    adaptiveHeight: true
                  }
                }
              ]
  		      });
  	      }

  	      if ($('.gallery.fixed-with-thumbs').length > 0) {

            // gall init (fixed with thumbs)
  		      $('.gallery.fixed-with-thumbs .slider').slick({
  			      slidesToShow: 1,
  			      slidesToScroll: 1,
  			      fade: true,
  			      asNavFor: '.thumbs-slider',
                  autoplay:gallAutoplay,
                  autoplaySpeed: gallAutoplaySpeed,
                  pauseOnHover:gallPauseHover,
              adaptiveHeight: true,
              lazyLoad: 'progressive',

              responsive: [
                {
                  breakpoint: 780,
                  settings: {
                    fade: false,
                    lazyLoad: 'progressive',
                  }
                }
              ]
  		      });

  		      $('.gallery.fixed-with-thumbs .thumbs-slider').slick({
  			      slidesToShow: 7,
  			      slidesToScroll: 7,
  			      asNavFor: '.gallery.fixed-with-thumbs .slider',
  			      centerMode: true,
  			      arrows: false,
  			      focusOnSelect: true,
  			      swipe: false,
              responsive: [
                {
                  breakpoint: 780,
                  // settings: 'unslick'
                }
              ]
  		      });

  		      $('a.hide-thumbs').click(function(event) {
  			      event.preventDefault();

  			      $(this).toggleClass('hidden');

  			      $('ul.thumbs-slider').slideToggle('slow');
  		      });
  	      }
          //custom gallery arrows
          var arrowClass = 'arrow-glyph9';
          if(typeof flo_js_data.gallery_arrow_type  != 'undefined'){
            arrowClass = flo_js_data.gallery_arrow_type;
          }
          jQuery('.slick-prev').addClass(arrowClass);
          jQuery('.slick-next').addClass(arrowClass);
        }

        /**
        * Language select — selecter.js
        **/
        if ($('.language-select select').length > 0) {
          $('.language-select select').selecter({});
        }

        /**
        * Masonry init
        **/
        if (masonryOn) {
          $('.masonry-grid:not(.flo-gallery-shortcode)').each(function() {
            $(this).find('.row').masonry({
              "percentPosition": true
            });
          });
        }

        
        // INITIATE Masonry FOR GRID VIEW
        var $container = jQuery('.flo-gallery-shortcode.masonry-grid .gallery-shortcode-wrap');
        $container.imagesLoaded(function(){
            $container.masonry({
                itemSelector: '.post-preview',
                // columnWidth: function(containerWidth){
                //     return containerWidth / 12;
                // }
            });
            jQuery('.post-preview img').addClass('not-loaded');
            jQuery('.post-preview img.not-loaded').lazyload({
                effect: 'fadeIn',
                load: function() {
                    // Disable trigger on this image
                    setTimeout(function(){
                        jQuery(this).removeClass("not-loaded");
                        $container.masonry('reloadItems');
                        $container.masonry('layout');
                    }, 1);
                   
                }
            });
            jQuery('.post-preview img.not-loaded').trigger('scroll');
        });

        
        if (isHeroSlider) {

          /**
          * Dropdown menu position (up / down)
          **/
          var $navigationBlock = $('.main-header.with-hero-slider.navigation-bottom ').find('.navigation-block');

          if ($navigationBlock.length > 0) {
            var $navigationBlockOffsetTop = $navigationBlock.offset().top;

            var basicPosition = 'up';
            
            $navigationBlock.addClass(basicPosition);

            $(window).scroll(function(event) {
              var windowScrollTop = $(window).scrollTop(); 

              var position = $navigationBlockOffsetTop / 2 > windowScrollTop ? 'up' : 'down';

              if (!$navigationBlock.hasClass(position)) {

                $navigationBlock
                  .removeClass(basicPosition)
                  .addClass(position);

                basicPosition = position;
              }
            });
          }
          
          /**
          * HeroSlider init
          **/
          $('.hero-slider').each(function(index, el) {

            var $heroSlider = $(this),
                $slider = $heroSlider.find('.slider'),
                $menu = $('.navigation-block nav > ul > li > a');

            var sliderAutoplay = floConfig.autoplay == 1 ? true : false;
            var sliderFadeEffect = floConfig.sl_transition_effect == 'fade' ? true : false;
            var sliderAutoplaySpeed = floConfig.autoplay_speed ? floConfig.autoplay_speed : false;

            $slider.on('init', function(slick) {
                menu_for_slider_color($slider, $menu);
                if ($('.navigation-block').hasClass('sticky')){
                  resetMenuStyle('style','data-style');  
                }
            });
            // Slider init
            $slider.slick({
              speed: $slider.data("transition_speed") !== "" ? $slider.data("transition_speed") : 800,
              autoplay: sliderAutoplay,
              autoplaySpeed: sliderAutoplaySpeed,
              fade: sliderFadeEffect,
              pauseOnHover: $slider.data("pause_on_hover") == "enabled" ? true : false
            });

              setTimeout(function () {
                  jQuery('div.inner').Lazy(
                      {effect:"fadeIn"}
                  );
              },0);
              $slider.on('afterChange',function () {
                  setTimeout(function () {
                      jQuery('div.inner').Lazy(
                          {effect:"fadeIn",
                              skip_invisible:false}
                      );
                  },0);
              });
              var arrowClass1 = 'arrow-glyph9';
              if(typeof flo_js_data.slideshow_arrow_type != 'undefined'){
                  arrowClass1 = flo_js_data.slideshow_arrow_type;
              }
              jQuery('.slick-prev').addClass(arrowClass1);
              jQuery('.slick-next').addClass(arrowClass1);

            

            // Scroll Down button
            $heroSlider.find('a.slide-down').click(function(event) {
              event.preventDefault();

              var heroSliderHeight = $heroSlider.outerHeight();
              var heroOffset = $heroSlider.offset();
              var heroScrollTop = heroOffset.top + heroSliderHeight;

              $('html, body').animate({
                  scrollTop: heroScrollTop
              }, 800);
            });

            // Background image check  
            if (floConfig.automatically_text_color == '1') {
              //$('.top-nav.desktop').find('.default-logo').hide();
              //$('.top-nav.desktop').find('.default-logo.white').show();
              //
              BackgroundCheck.init({
                targets: '.top-nav, .navigation-block:not(.sticky), button.slick-prev, button.slick-next, .slide.with-image article.content, .flo_header_menu_and_logo .fix',
                images: '.hero-slider .inner'
              });


              $slider.on('setPosition afterChange', function() {
                  //$('.top-nav.desktop').find('.default-logo').hide();
                  //$('.top-nav.desktop.background--light').find('.default-logo.black').show();
                  //$('.top-nav.desktop.background--dark').find('.default-logo.white').show();
                BackgroundCheck.refresh();
              });
            }
 
            $slider.on('afterChange  ', function(event, slick, currentSlide, nextSlide){
              menu_for_slider_color($slider, $menu); 
            });

            function menu_for_slider_color( $slider, $menu ){
              var curr_slider = $slider.find('.slick-current'),
                  menu_color = curr_slider.attr('data-menu-color'),
                  $header_obj = jQuery('.search-form label, .search-form input[type="text"], .search-form button[type="submit"], .text-right, .text-right .social-links li a, .hero-slider .slick-prev, .hero-slider .slick-next, .right_side > ul > li > a, .left_side > ul > li > a');
 
              if ( menu_color !== undefined ) {
                $menu.css('color', menu_color).addClass('colored');

                $header_obj.css('color', menu_color).addClass('colored');
              }  else {
                $menu.css('color', '').removeClass('colored');
                $header_obj.css('color', '').removeClass('colored');
              }
            }

            //Video Slide
            var initVideo = function($slider, $video) { 
              

              setTimeout(function() {
                  if(jQuery('.slick-active').length && jQuery('.slick-active').attr('video-autoplay') !== undefined){
                      jQuery('.video-container').bind('DOMSubtreeModified', function(e){
                          setTimeout(function() {
                              if(jQuery('.video-container').hasClass('vjs-ended')){
                                  jQuery('a.close-button').click();
                              }

                          }, 1000);
                      });
                  }
                  

              }, 1000);

              $('header.main-header').addClass('video-active');
              $slider.addClass('video-active');

              $video.find('.video-block').floVideo('create');

              // Disable Autoplay when video is on
              if (sliderAutoplay) {
                $slider.slick('slickSetOption', "autoplay", false, false);
              }
            };

            var closeVideo = function($slider, $video) {
              $video.find('.video-block').floVideo('destroy');
              $video_container.data('plugin_floVideo', {}).data('videoObj', {});

              // Enable Autoplay after then video is hide
              if (sliderAutoplay) {
                $slider.slick('slickSetOption', 'autoplay', true, false);
              }

              $('header.main-header').removeClass('video-active');
              $slider.removeClass('video-active');
            };

            // Enable video slide autoplay on fist slide
            (function(slider) {
              var $initialSlide = $(slider.slick('getSlick').$slides[0]),
                  $video = $initialSlide.find('.video-block');

              if ($initialSlide.hasClass('video-slide') && $initialSlide.attr('video-autoplay') !== undefined) {
                initVideo(slider, $initialSlide);
              }
            })($slider);



            $heroSlider.find('.slide.video-slide').each(function(index, el) {
              var $video = $(this),
                  $playButton = $(this).find('.play-button'),
                  $closeButton = $(this).find('.close-button');

              $playButton.click(function(event) {
                event.preventDefault();

                initVideo($slider, $video);

                $('body').on('keyup', function(event) {
                  if (event.keyCode == 27) {
                    closeVideo($slider, $video);
              
                    $('body').off('keyup');
                  }
                });

                // close the video iframe when the video ends
                jQuery('.video-container').bind('DOMSubtreeModified', function(e){
                    setTimeout(function() {
                        if(jQuery('.video-container').hasClass('vjs-ended')){
                            jQuery('a.close-button').click();
                        }

                    }, 2000);
                });

              });

              $closeButton.click(function(event) {
                event.preventDefault();

                closeVideo($slider,   $video);
              });
            });

            $slider.on('beforeChange', function() {
              if ($slider.find('.video-block.video-active').length) {
                var $video = $slider.find('.video-block.video-active').closest('.slide.video-slide');
                closeVideo($slider, $video);
              }
            });

            $slider.on('beforeChange', function(event, slick, currentSlide, nextSlide){
              var $nextSlide = $(slick.$slides[nextSlide]);

              if ($nextSlide.hasClass('video-slide') && $nextSlide.attr('video-video-block') !== undefined) {
                initVideo($slider, $nextSlide);
              }
            });

          });
        }

        /**
        * Init hamburger button for hamburger menu
        **/
        if (floConfig.headerSlideDown) {
          $('header.main-header a.menu-open, header.main-header a.menu-open_label').click(function(e) {
            e.preventDefault();

            $(this).toggleClass('active');

            $('header.main-header.menu-slide-down .header_main-nav').slideToggle();
          });
        }
   
        /**
        * Horizontal slider init
        **/
        if ($('.block.horizontal-slider').length > 0 ) {
            var NearBySliderAutoplay = floConfig.autoplay == 1 ? true : false;
            var NearBySliderAutoplaySpeed = floConfig.autoplay_speed ? floConfig.autoplay_speed : false;

          $('.block.horizontal-slider .slider').slick({
            slidesToShow: 1,
            variableWidth: true,
            infinite: true,
            centerMode: true,
            autoplay: NearBySliderAutoplay,
            autoplaySpeed: NearBySliderAutoplaySpeed
          });

            var arrowClass2 = 'arrow-glyph9';
            if(typeof flo_js_data.slideshow_arrow_type != 'undefined'){
                arrowClass2 = flo_js_data.slideshow_arrow_type;
            }
            jQuery('.slick-prev').addClass(arrowClass2);
            jQuery('.slick-next').addClass(arrowClass2);
        }

        /**
        * Video Block init (up / down)
        **/
        if ($('.block.video-element').length > 0) {
          $('.block.video-element').each(function() {
            var $block = $(this),
                $video = $block.find('.video-block'),
                $playButton = $block.find('.title'),
                $closeButton = $block.find('a.close');

            $playButton.click(function(event) {
              event.preventDefault();

              $video.floVideo('create');
              $block.addClass('active');
            });

            $closeButton.click(function(event) {
              event.preventDefault();

              $video.floVideo('destroy');
              $block.removeClass('active');
            });


          });
        } 


        /**
        * Fancybox init
        **/
        if($("a[data-fancybox-group^='prettyPhoto']").length > 0){
          $("a[data-fancybox-group^='prettyPhoto']").fancybox({
              openEffect : 'elastic',
              openSpeed  : 150,
              closeEffect : 'elastic',
              closeSpeed  : 150,
              closeClick : true,
              padding: 0,
              autoCenter: false,
              openMethod : 'changeIn',
              wrapCSS: 'flo-fancybox',

              helpers: {
                  overlay: {
                    locked: false
                  }
              },

                
                
                nextEffect  : 'elastic',
                prevEffect  : 'elastic'


              
          });
        }

        $('.item').each(function(){
          $(this).find('a.popup_play_button').on('click', function (event) {
            if($(this).parents('div.item').find('.android_video').length > 0 && $isAndroid){
              var $androidframe = $(this).parents('div.item').find('.android_video').html();
              $video_container.append($androidframe).show();
            }else {
                event.stopImmediatePropagation();
                var src = $(this).parent().data('video-url');
                $video_container.data('video-url', src).show().floVideo('create', true);
                return false;
            }
          });
        });

        var closePopup = function(){
          $($video_container).html('<a href="#" class="close-button">×</a>');
          $video_container.hide().removeClass('video-active').floVideo('destroy');
          $video_container.data('plugin_floVideo', {}).data('videoObj', {});
          $('header.main-header').removeClass('video-active');
        };

        jQuery('body').on('click','a.close-button',function(event) {
          event.preventDefault();
          closePopup();
        });

        $('body').on('keyup', function(event) {
          if (event.keyCode == 27) {
            closePopup();
          }
        });
        setTimeout(function(){
          $('.single-video #vjs_video_3').css('padding','0');
        }, 50);
        if($('.no_img_thumb').length > 0){
          setTimeout(function(){
            if($('.android_video').length > 0 && $isAndroid){
              $('.android_video').show();
              $('.video figure').hide();
            }else{
              $('.no_img_thumb').find('.icon').click();
            }
          }, 100);
        }else{
          if($('.android_video').length > 0 && $isAndroid){
            $('body').on('click','.single_video_btn',function(e){
              e.preventDefault();
              var youtube = $(this).parents('.slide').find('.video-block').data('video-url');
              if(youtube.indexOf('youtu') > -1){
                return true;
              }else {

                $('.android_video').css('height', '100vh').show();
                $('.video figure').hide();

                $(this).parents('.slide').find('.video-block #vjs_video_3').hide();
                $(this).parents('.slide').find('.video-block .video-container').hide();
                $(this).parents('.hero-slider').find('.slide.video-slide .video-block').css({
                  'background-color': 'transparent',
                  'z-index': 0,
                  'height': 0
                });
                $(this).parents('.slide').find('.inner').hide();
              }

            });
            $('.slideshow_close_btn').on('click', function(e){
              e.preventDefault();

              var vidsrc = $(this).parents('.slide').find('.android_video iframe').attr('src');

              $(this).parents('.slide').find('.android_video iframe').attr('src','');
              $('.video-block #vjs_video_3').show();
              $('.video-block .video-container').show();
              $(this).parents('.slide').find('.android_video iframe').attr('src', vidsrc);
              $('.android_video').hide();

              $(this).parents('.hero-slider').find('.slide.video-slide .video-block').css({
                'background-color': '#000000',
                'z-index': 100,
                'height': '100%'
              });
              $('.slide').find('.inner').show();
            });
          }
        }
        if($('.single-video').length > 0 ) {
          $('html, body').animate({
            scrollTop: $('header').height() * 2
          }, 800);
        }
      }

    },


    'single_post': {
      init: function() {
        $('.page.open-post').each(function(index, el) {
          var $openPost = $(el);
          var $content = $openPost.find('.post-content');
          var contentOffset = $content.offset();

          $openPost.find('a.slide-down').click(function(event) {
            event.preventDefault();
            $('html, body').animate({
              scrollTop: contentOffset.top
            }, 800);
          });
        });
      }
    }
  };

  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Porto;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {

      UTIL.fire('common');

      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      UTIL.fire('common', 'finalize');
    }
  };

  
  // Load Events
  $(window).load(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
